<template>
  <div>
    <!-- 区域库存分析 -->
    <filter-view :loading="loading" @submit="loadEchart">
      <stock-Filter :show-opt="showOpt" :query="query" @init="loadEchart" />
    </filter-view>
    <div class="result">
      <div class="padding-10 fs-small fc-g">分析结果</div>
      <div class="round-panel bc-w">
        <div v-if="store && store.length > 0">
          <div class="c h sb">
            <div class="no-flex padding-10">显示图表前</div>
            <el-input-number v-model="showRankNum" size="mini" :min="1" :max="1000" :step="1" :precision="0" @change="draw" />
          </div>
          <div class="sep-t" ref="gdBarChart" style="height:420px;"></div>
          <div class="sep-t" ref="gdPieChart" style="height:420px;"></div>
        </div>
        <div class="fc-g ta-c padding-10" v-if="!store || !store.length">没有分析数据，请更改统计分析条件或稍候重试。</div>
      </div>
      <template v-if="store && store.length > 0">
        <div class="padding-10 fs-small fc-g">数据列表</div>
        <div class="round-panel bc-w">
          <div class="padding-05 sep-b" v-for="o in pagedStore" :key="o.warehouseId">
            <div class="c h wrap fs-small lh-150">
              <div class="padding-05" style="width: 100%">
                <div>{{ o.warehouseName || "-" }}，数量{{ o.goodsCount || "-" }}</div>
              </div>
            </div>
          </div>
          <div class="ta-c">
            <el-pagination class="h5-pagination" :total="totalElements" :current-page.sync="page" :page-size="size" layout="prev, pager, next" hide-on-single-page />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import filterView from "../assembly/filter";
import stockFilter from "../assembly/filter/stock";
import request from "@/utils/request";
export default {
  components: {
    filterView,
    stockFilter,
  },
  data() {
    let now = new Date();
    return {
      loading: false,
      page: 1,
      size: 6,
      totalElements: null,
      store: [], //图表数据
      showRankNum: 3,
      chart: {
        gdBarChart: null,
        gdPieChart: null,
      },
      showOpt: {
        showRangGoods: true, //商品范围
        showCommodity: true, //指定商品
        showLevel: false, //汇总级别
        showWarehouseGroup: true,
      },
      query: {
        addressType: "area",
        treeNodeId: null,
        treeNodeType: null,
        StandardAreaGroup: null, //标准行政区域
        warehouseGroup: "distributor",
        brandId: null,
        seriesId: null,
        categoryId: null,
        goods: null, //指定商品
        page: 0,
        size: 10000,
      },
    };
  },
  watch: {
    store() {
      this.page = 1;
    },
  },
  computed: {
    pagedStore() {
      let start = (this.page - 1) * this.size,
        len = this.size;
      if (start + len > this.store.length) {
        len = this.store.length - start;
      }
      return this.store.slice(start, start + len);
    },
  },
  methods: {
    makeParams() {
      let data = JSON.parse(JSON.stringify(this.query));
      if (data.addressType == "standard") {
        if (this.query.StandardAreaGroup == 0) {
          data.StandardAreaGroup = "country";
        }
        if (this.query.StandardAreaGroup == 1) {
          data.StandardAreaGroup = "province";
        }
        if (this.query.StandardAreaGroup == 2) {
          data.StandardAreaGroup = "city";
        }
        if (this.query.StandardAreaGroup == 3) {
          data.StandardAreaGroup = "district";
        }
        delete data.treeNodeType;
      } else {
        data.treeNodeType = this.query.treeNodeType;
        delete data.StandardAreaGroup;
      }
      if (data.goods && data.goods.length)
        data.skuIds = data.goods.map((o) => o.id);
      delete data.goods;
      delete data.dateRange;
      return data;
    },
    loadEchart() {
      let data = Object.assign({}, this.makeParams());
      this.loading = true;
      if (!!!data.treeNodeType) return false;
      request({
        url: "@host:analysis;api/analy/stock/count",
        method: "get",
        params: data,
      })
        .then((res) => {
          this.store = res.content;
          this.totalElements = res.totalElements;
          this.$nextTick((_) => {
            this.draw();
          });
        })
        .catch((err) => {
          this.store = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    draw(val) {
      if (!val) {
        this.showRankNum = 3;
      }
      this.drawGdBarChart();
      this.drawGdPieChart();
    },
    drawGdBarChart() {
      if (!this.chart.gdBarChart) {
        this.chart.gdBarChart = echarts.init(this.$refs.gdBarChart, "light");
      }
      let xName = [];
      let xData = [];
      let yName = [];
      let svgp = [];
      let seriesData = [];
      yName.push("件");
      xName.push("数量");
      svgp.push("数量");
      (this.store || []).forEach((o) => {
        xData.push(o.warehouseName || "不详");
        seriesData.push(o.goodsCount);
      });

      this.chart.gdBarChart.clear();
      this.chart.gdBarChart.setOption({
        tooltip: {
          trigger: "axis",
          confine: true,
        },
        title: {
          text: "库存数量前" + this.showRankNum,
          left: "center",
        },
        color: ["#3398DB"],
        grid: {
          left: 50,
          right: 20,
          bottom: 50,
          top: 50,
        },
        xAxis: [
          {
            type: "category",
            data: xData.slice(0, this.showRankNum),
            axisLabel: {
              formatter: function (value) {
                if (value.length > 5) {
                  return value.substr(0, 5) + "…";
                }
                return value;
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: yName,
          },
        ],
        series: [
          {
            name: xName,
            type: "bar",
            data: seriesData.slice(0, this.showRankNum),
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "top",
                },
              },
            },
          },
        ],
        dataZoom: {
          show: seriesData.slice(0, this.showRankNum).length > 5, // 为true 滚动条出现
          filterMode: "weakFilter",
          type: "slider", // 有type这个属性，滚动条在最下面，也可以不行，写y：36，这表示距离顶端36px，一般就是在图上面。
          height: 15,
          start: 0,
          end: (5 / seriesData.slice(0, this.showRankNum).length) * 100,
          bottom: 10,
          handleIcon:
            "M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
          handleSize: "80%",
          zoomLock: true,
          showDetail: false,
        },
      });
    },
    drawGdPieChart() {
      if (!this.chart.gdPieChart) {
        this.chart.gdPieChart = echarts.init(this.$refs.gdPieChart, "light");
      }
      let xName = [];
      xName.push("数量");
      let arry = this.store;
      let newArray = this.store.map((o) => {
        return {
          name: o.warehouseName || "不详",
          value: o.goodsCount == 0 ? null : o.goodsCount,
        };
      });

      this.chart.gdPieChart.clear();
      this.chart.gdPieChart.setOption({
        title: {
          text: "汇总分析前" + this.showRankNum,
          left: "center",
        },
        tooltip: {
          trigger: "item",
          confine: true,
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        series: [
          {
            name: xName,
            type: "pie",
            radius: "40%",
            center: ["50%", "50%"],
            data: newArray.slice(0, this.showRankNum),
            label: {
              formatter: function (p) {
                if (p.name.length > 5) return p.name.substr(0, 5) + "…";
                else return p.name;
              },
            },
          },
        ],
      });
    },
  },
};
</script>




